import React, {useState} from'react';
import './loginform.scss';
import {apiPost, setCookie} from '../../js/shared.js'
import SMLogo from '../../images/SMLogo.png'
import EmailCompleteDropDown from './emailcompletedropdown';


function LoginForm(props){

    const currentHour = new Date().getHours();
    const timeOfDay= currentHour > 16 ? 'evening' : currentHour > 11 ? 'afternoon' : 'morning';
    const greeting = `Good ${timeOfDay}, please sign in`

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState('');
    



    const PasswordCheckForEnterKey = e => {
        if(e.key === 'Enter')
            doLogin();
    }




    const EmailChosen = val => {
        setEmail(val);
        document.getElementById('Password').focus();
    }




    const doLogin = async () => {

        setIsDisabled('disabled');

        const response = await apiPost('/auth/validate', '', {EmailAddress: email, Password: password})
        if(response)
        {
            const result = response.ok ? await response.json() : [];
            if(result.StaffMemberId)
            {
                /*
                await db.Configuration.clear();
                await db.Configuration.put({Name:'EntityId',Value:result.EntityId});
                await db.Configuration.put({Name:'FirstName', Value:result.FirstName});
                await db.Configuration.put({Name:'LastName', Value:result.LastName});
                await db.Configuration.put({Name:'StaffMemberId', Value:result.StaffMemberId});
                */
                setCookie('EntityId', result.EntityId, result.DefaultTimeout / 10);
                setCookie('StaffMemberId', result.StaffMemberId, result.DefaultTimeout / 10);
                setCookie('FirstName', result.FirstName, result.DefaultTimeout / 10);
                setCookie('LastName', result.LastName, result.DefaultTimeout / 10);
                setCookie('LoggedIn', 'Yes', result.DefaultTimeout / 10);
                setCookie('DefaultTimeout', result.DefaultTimeout, result.DefaultTimeout / 10);
                setIsDisabled('');
                window.location.reload(true);

            } else {
                alert('Error during login - please check and try again');
                setIsDisabled('');
            }
        }


    }

    return(
        <>

            <div className="headerImgContainer">
                <img src={SMLogo} className="headerImg"></img>
            </div>

            <div className="loginDialog">

                <div className="form-group">
                    <p className="smallLead">
                        {greeting}
                    </p>                    
                </div>

                <div className="form-group">
                    <label>Email Address:</label>
                    <EmailCompleteDropDown Selection={val => EmailChosen(val)}></EmailCompleteDropDown>
                </div>

                <div className="form-group">
                    <label>Password:</label>
                    <input id="Password" type="password" onKeyPress={e => PasswordCheckForEnterKey(e)} onChange={e => setPassword(e.target.value)} value={password} className="form-single-text" />
                </div><br />

                {
                    isDisabled  === 'disabled'
                    ? 
                    <>
                        <span id="LoginInProgress"><i className="fas fa-spinner fa-spin mr-5"></i></span>Login in progress...
                    </>
                    :
                    <>
                        <div className="form-group">
                            <p><button onClick={() => doLogin()} className="btn btn-primary" disabled={isDisabled.toString()}>Login Now</button></p>
                            <br />
                        </div>
                    </>
                }
            </div>

        </>
    );
}

export default LoginForm;