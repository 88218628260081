import React, {useState,useEffect} from 'react';
import LocationListDropdown from '../shared/locationlistdropdown';
import {EntityId} from '../../js/db';
import {apiGet, apiPost} from '../../js/shared';
import moment from 'moment';

function WhoIsWorking() {

    const [LocationId, setLocationId] = useState(0);
    const [NameList, setNameList] = useState([]);
    const [WhichDate, setWhichDate] = useState('Today');


    //Runs on change of location to get list of rota entries
    useEffect(() => {
        getRota('Today');
    }, [LocationId]);



    //Sets the view to today or tomorrow
    const setView = val => {
        setWhichDate(val);
        getRota(val);
    };


    //Gets the data
    const getRota = async(val) => {

        const entityId = await EntityId();
        const data ={
            Data: {
                LocationId,
                Date: val === 'Today' ? moment().format('DD/MM/YYYY') : moment().add('days',1).format('DD/MM/YYYY')
            }
        }

        //Get staff list
        let staffList = [];
        const staffRequest = await apiGet('/staffmember/list', entityId);
        if(staffRequest.ok && staffRequest.status === 200) {
            staffList = await staffRequest.json();
        }

        if(staffList) {
            //Get rota entries
            const rotaReq = await apiPost('/rota/whosworking', entityId, data);
            if(rotaReq.ok && rotaReq.status === 200) {
                
                const rotaRes = await rotaReq.json();
                let nameList = [];

                rotaRes.forEach(entry => {
                    const smName = staffList.find(x => x.StaffMemberId.toString().toLowerCase() === entry.StaffMemberId.toString().toLowerCase());
                    if(smName !== undefined && smName.Enabled)
                        nameList.push(`${smName.FirstName}`);
                });
                setNameList(nameList);

            }   
        }         

    }



    const WhosWorking = NameList.map((person,idx) => {
        return <p key={idx}>{person}</p>
    })


    return (
        <>
            <h5>Who's Working</h5>
            <p>
                <input checked={WhichDate === 'Today'} type="radio" onChange={() => setView('Today')} radioGroup="WhosWorking"></input> Today<br />
                <input checked={WhichDate === 'Tomorrow'} type="radio" onChange={() => setView('Tomorrow')} radioGroup="WhosWorking"></input> Tomorrow
            </p>
            <div>
                <LocationListDropdown Selection={e => setLocationId(e)} Excludes={{Select:true}} ExcludeAll={true} Requirements={{IsRotaLocation:true}}></LocationListDropdown>
            </div>
            {WhosWorking}
        </>
    );
}

export default WhoIsWorking;