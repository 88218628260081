import React, { useState, useEffect, lazy, Suspense } from "react";
import { getCookie, setCookie, deleteCookie } from "../js/shared";
import ActivityIndicator from "./shared/activityindicator";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LoginForm from "./login/loginform.jsx";
import Menu from "./menu/menu.jsx";
import Dashboard from "./dashboard/dashboard.jsx";
import "./app.scss";

const Brand = lazy(() => import("./brand/brand.jsx"));
const EditBrand = lazy(() => import("./brand/editbrand.jsx"));
const BrandContact = lazy(() => import("./brand/brandcontact.jsx"));
const EditBrandContact = lazy(() => import("./brand/editbrandcontact.jsx"));
const BrandAttribute = lazy(() => import("./brand/brandattribute.jsx"));
const EditBrandAttribute = lazy(() => import("./brand/editbrandattribute.jsx"));
const Colour = lazy(() => import("./brand/colour"));
const EditColour = lazy(() => import("./brand/editcolour.jsx"));
const Category = lazy(() => import("./category/category.jsx"));
const EditCategory = lazy(() => import("./category/editcategory.jsx"));
const MergeCategory = lazy(() => import("./category/merge"));
const Currency = lazy(() => import("./currency/currency.jsx"));
const EditCurrency = lazy(() => import("./currency/editcurrency.jsx"));
const Location = lazy(() => import("./location/location.jsx"));
const EditLocation = lazy(() => import("./location/editlocation.jsx"));
const Order = lazy(() => import("./order/order.jsx"));
const EditOrder = lazy(() => import("./order/editorder.jsx"));
const OrderItems = lazy(() => import("./order/orderitems.jsx"));
const OrderPhotos = lazy(() => import("./order/orderphotos.jsx"));
const Product = lazy(() => import("./product/product.jsx"));
const EditProduct = lazy(() => import("./product/editproduct.jsx"));
const ProductHistory = lazy(() => import("./product/producthistory.jsx"));
const Season = lazy(() => import("./season/season.jsx"));
const EditSeason = lazy(() => import("./season/editseason.jsx"));
const SizeGroup = lazy(() => import("./size/sizegroup.jsx"));
const EditSizeGroup = lazy(() => import("./size/editsizegroup.jsx"));
const Size = lazy(() => import("./size/size.jsx"));
const EditSize = lazy(() => import("./size/editsize.jsx"));
const StaffMember = lazy(() => import("./staffmember/staffmember.jsx"));
const StaffMemberEdit = lazy(() => import("./staffmember/staffmemberedit"));
const StaffMemberLocations = lazy(() => import("./staffmember/locations"));
const HolidayPeriods = lazy(() => import("./staffmember/holidayperiods"));
const StaffPermissions = lazy(() => import("./staffmember/permissions"));
const Stock = lazy(() => import("./stock/stock.jsx"));
const ManualSale = lazy(() => import("./stock/manualsale"));
const Barcodes = lazy(() => import("./stock/barcodes.jsx"));
const Returns = lazy(() => import("./stock/returns.jsx"));
const Relocations = lazy(() => import("./stock/relocations.jsx"));
const Withdrawl = lazy(() => import("./withdrawl/withdrawl.jsx"));
const Rota = lazy(() => import("./rota/rota.jsx"));
const MonthlyHours = lazy(() => import("./rota/monthlyhours"));
const SalesHistory = lazy(() => import("./sales/history"));
const Customers = lazy(() => import("./sales/customers"));
const EditCustomer = lazy(() => import("./sales/editcustomer"));
const rptAveragePerformance = lazy(() => import("./reporting/avgperformance/averageperformance"));
const rptOrders = lazy(() => import("./reporting/orders/orders"));
const rptRelocations = lazy(() => import("./reporting/relocations/relocations"));
const rptReturns = lazy(() => import("./reporting/returns/returns"));
const rptSales = lazy(() => import("./reporting/sales/sales"));
const rptSalesPerformance = lazy(() => import("./reporting/salesperformance/salesperformance"));
const rptSalesByStaff = lazy(() => import("./reporting/staffperformance/staffperformance"));
const rptSellThrough = lazy(() => import("./reporting/sellthrough/sellthrough"));
const rptStock = lazy(() => import("./reporting/stock/stock"));
const rptHistoricStock = lazy(() => import("./reporting/historicstock/historicstock"));
const rptVouchers = lazy(() => import("./reporting/vouchers/vouchers"));
const rptUndeliveredItems = lazy(() => import("./reporting/undelivered/undelivereditems"));
const EndOfDay = lazy(() => import("./reporting/endofday/endofday"));
const Documents = lazy(() => import("./documents/documents"));

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

function App(props) {
  const [spinnerVisible, setSpinnerVisible] = useState(false);

  const ClearLogin = () => {
    setSpinnerVisible(true);
    setTimeout(() => {
      deleteCookie("FirstName");
      deleteCookie("LastName");
      deleteCookie("StaffMemberId");
      deleteCookie("EntityId");
      deleteCookie("LoggedIn");
      deleteCookie("DefaultTimeout");
      window.location.reload(true);
    }, 1500);
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      setCookie("LoggedIn", "Yes", parseInt(getCookie("DefaultTimeout")) / 10);
      setCookie("FirstName", getCookie("FirstName"), parseInt(getCookie("DefaultTimeout")) / 10);
      setCookie("LastName", getCookie("LastName"), parseInt(getCookie("DefaultTimeout")) / 10);
      setCookie("StaffMemberId", getCookie("StaffMemberId"), parseInt(getCookie("DefaultTimeout")) / 10);
      setCookie("EntityId", getCookie("EntityId"), parseInt(getCookie("DefaultTimeout")) / 10);
      setCookie("DefaultTimeout", getCookie("DefaultTimeout"), parseInt(getCookie("DefaultTimeout")) / 10);
    });
    setInterval(() => {
      if (getCookie("LoggedIn") !== "Yes") {
        ClearLogin();
      }
    }, 60000);
  }, []);

  return (
    <>
      {getCookie("LoggedIn") === "Yes" ? (
        <Router>
          <ActivityIndicator Visible={spinnerVisible} />

          <div className="Container">
            <div className="Menu">
              <Menu LogoutAction={ClearLogin} />
            </div>

            <div className="Content">
              <Suspense fallback={<ActivityIndicator />}>
                <Route component={ScrollToTop}></Route>
                <Route exact path="/" component={Dashboard}></Route>
                <Route exact path="/documents" component={Documents}></Route>
                <Route exact path="/Brand" component={Brand}></Route>
                <Route exact path="/Brand/Edit/:Id" component={EditBrand}></Route>
                <Route exact path="/brand/contact/:Id" component={BrandContact}></Route>
                <Route exact path="/brand/contact/:BrandId/edit/:Id" component={EditBrandContact}></Route>
                <Route exact path="/brand/attribute/:Id" component={BrandAttribute}></Route>
                <Route exact path="/brand/attribute/:BrandId/edit/:Id" component={EditBrandAttribute}></Route>
                <Route exact path="/brand/colour/:Id" component={Colour}></Route>
                <Route exact path="/brand/colour/:BrandId/edit/:Id" component={EditColour}></Route>
                <Route exact path="/category" component={Category}></Route>
                <Route exact path="/category/edit/:Id" component={EditCategory}></Route>
                <Route exact path="/category/merge/:Id" component={MergeCategory}></Route>
                <Route exact path="/currency" component={Currency}></Route>
                <Route exact path="/currency/edit/:Id" component={EditCurrency}></Route>
                <Route exact path="/location" component={Location}></Route>
                <Route exact path="/location/edit/:Id" component={EditLocation}></Route>
                <Route exact path="/order" component={Order}></Route>
                <Route exact path="/order/edit/:Id" component={EditOrder}></Route>
                <Route exact path="/order/items/:Id" component={OrderItems}></Route>
                <Route exact path="/order/photos/:Id" component={OrderPhotos}></Route>
                <Route exact path="/product" component={Product}></Route>
                <Route exact path="/product/edit/:Id" component={EditProduct}></Route>
                <Route exact path="/product/history/:Id" component={ProductHistory}></Route>
                <Route exact path="/rota" component={Rota}></Route>
                <Route exact path="/rota/monthlyhours" component={MonthlyHours}></Route>
                <Route exact path="/season" component={Season}></Route>
                <Route exact path="/season/edit/:Id" component={EditSeason}></Route>
                <Route exact path="/sizes/" component={SizeGroup}></Route>
                <Route exact path="/sizes/sizegroup/edit/:Id" component={EditSizeGroup}></Route>
                <Route exact path="/sizes/sizegroup/:SizeGroupId" component={Size}></Route>
                <Route exact path="/sizes/sizegroup/:SizeGroupId/edit/:Id" component={EditSize}></Route>
                <Route exact path="/staffmember" component={StaffMember}></Route>
                <Route exact path="/staffmember/permissions" component={StaffPermissions}></Route>
                <Route exact path="/staffmember/edit/:Id" component={StaffMemberEdit}></Route>
                <Route exact path="/staffmember/locations/:Id" component={StaffMemberLocations}></Route>
                <Route exact path="/staffmember/holidayperiods" component={HolidayPeriods}></Route>
                <Route exact path="/stock" component={Stock}></Route>
                <Route exact path="/stock/barcodes" component={Barcodes}></Route>
                <Route exact path="/stock/returns" component={Returns}></Route>
                <Route exact path="/stock/relocations" component={Relocations}></Route>
                <Route exact path="/stock/manualsale" component={ManualSale}></Route>
                <Route exact path="/sales/history" component={SalesHistory}></Route>
                <Route exact path="/customers" component={Customers}></Route>
                <Route exact path="/customers/edit/:CustomerId" component={EditCustomer}></Route>
                <Route exact path="/withdrawl" component={Withdrawl}></Route>
                <Route exact path="/reporting/averageperformance" component={rptAveragePerformance}></Route>
                <Route exact path="/reporting/orders" component={rptOrders}></Route>
                <Route exact path="/reporting/relocations" component={rptRelocations}></Route>
                <Route exact path="/reporting/returns" component={rptReturns}></Route>
                <Route exact path="/reporting/sales" component={rptSales}></Route>
                <Route exact path="/reporting/salesperformance" component={rptSalesPerformance}></Route>
                <Route exact path="/reporting/staffperformance" component={rptSalesByStaff}></Route>
                <Route exact path="/reporting/sellthrough" component={rptSellThrough}></Route>
                <Route exact path="/reporting/stock" component={rptStock}></Route>
                <Route exact path="/reporting/historicstock" component={rptHistoricStock}></Route>
                <Route exact path="/reporting/undelivered" component={rptUndeliveredItems}></Route>
                <Route exact path="/reporting/vouchers" component={rptVouchers}></Route>
                <Route exact path="/reporting/endofday" component={EndOfDay}></Route>
              </Suspense>
            </div>
          </div>

          <div className="toast"></div>
        </Router>
      ) : (
        <LoginForm />
      )}
    </>
  );
}

export default App;
