import * as jsPDF from "jspdf";
import numeral from "numeral";
import * as qrcode from "qrious";

const prodBase = "https://smproductionapi.azurewebsites.net";
const devBase = "https://smdevapi.azurewebsites.net";
const localBase = "http://localhost:5002";

const prodStore = "https://smprodstorage.blob.core.windows.net";
const devStore = "https://stockmagicdevstorage.blob.core.windows.net";
const localStore = "https://stockmagicdevstorage.blob.core.windows.net";

let baseUrl = localBase;
let baseStoreUrl = localStore;
if (window.location.href.indexOf("dev.backoffice.stockmagic.co.uk") > 0) {
  baseUrl = devBase;
  baseStoreUrl = devStore;
} else if (window.location.href.indexOf("backoffice.stockmagic.co.uk") > 0) {
  baseUrl = prodBase;
  baseStoreUrl = prodStore;
}

baseUrl = prodBase;

export const getBaseUrl = baseUrl;
export const getBaseStoreUrl = baseStoreUrl;

/* Cookie functions */

export const getCookie = (cname) => {
  const name = cname + "=";
  const allCookies = document.cookie.split(";");
  for (const idx of allCookies) {
    let cookie = idx;
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, length = 1) => {
  const d = new Date();
  d.setTime(d.getTime() + length * 1000 * 60 * 10);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const deleteCookie = (cname) => {
  var expires = "expires=Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = cname + "='';" + expires + ";path=/";
};

export const ValidateUUID = (uuid) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regex.test(uuid);
};

/* End */

/* API functions */
export const apiPut = async (url, entityId, data) => {
  return await fetch(`${baseUrl}${url}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      entityid: entityId,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const apiPost = async (url, entityId, data) => {
  return await fetch(`${baseUrl}${url}`, {
    method: "POST",
    mode: "cors",
    headers: {
      entityid: entityId,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const apiPostFile = async (url, entityId, data) => {
  try {
    return await fetch(`${baseUrl}${url}`, {
      method: "POST",
      headers: {
        entityid: entityId,
      },
      body: data,
    });
  } catch (error) {
    return null;
  }
};

export const apiGet = async (url, entityId) => {
  return await fetch(`${baseUrl}${url}`, {
    method: "GET",
    mode: "cors",
    headers: {
      entityid: entityId,
    },
  });
};

export const apiDelete = async (url, entityId) => {
  return await fetch(`${baseUrl}${url}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      entityid: entityId,
    },
  });
};

/* Toast messages */
export const toast = (type, message) => {
  const container = document.getElementsByClassName("toast")[0];
  if (container) {
    container.innerHTML = message;
    container.classList.add(type);
    container.classList.add("show");
    setTimeout(() => {
      container.classList.remove(type);
      container.classList.remove("show");
    }, 3000);
  }
};

/* Barcode production */
export const createBarcodes = (list) => {
  var doc = new jsPDF({
    orientation: "landscape",
    unit: "mm",
    format: [90, 150],
  });

  let firstPage = true;
  list.forEach((item) => {
    if (firstPage) {
      firstPage = false;
    } else {
      doc.addPage();
    }

    const barcode = `${item.ProductId}/${item.SizeId}/${item.ColourId}/${item.LocationId}`;
    const qr = new qrcode({
      level: "H",
      size: 65,
      padding: 0,
      value: barcode,
    });
    const b64 = qr.toDataURL();

    doc.setFontSize(8);
    const styleString = item.Style !== null ? item.Style : "";
    doc.text(
      [
        `${item.BrandName}`,
        `${item.CategoryName}`,
        `${item.DisplayColour}`,
        `${styleString}`,
      ],
      2,
      6
    );
    doc.setFontSize(10);
    doc.text(`Size: ${item.SizeLabel}`, 2, 22);

    doc.setFontSize(11);
    doc.text(`£${numeral(item.CurrentRRP).format("0.00")}`, 2, 28);

    doc.setFontSize(8);
    doc.text(barcode, 31, 22);

    doc.setFontSize(8);
    doc.text(item.InternalCode, 26, 28);

    doc.addImage(b64, 32, 2);
  });

  doc.output("dataurlnewwindow");
};

export const DownloadFile = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};
