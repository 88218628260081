import React, {useEffect, useState} from 'react';
import {EntityId} from '../../js/db';
import {apiGet} from '../../js/shared';

function WhatIsSelling() {

    const [Sales, setSales] = useState([]);
    const [loadMessageVisible, setLoadMessageVisible] = useState(true);


    useEffect(() => {

        const getData = async() => {

            const entityId = await EntityId();

            const dataReq = await apiGet('/sale/whatsselling', entityId);
            if(dataReq.ok && dataReq.status === 200) {
                const dataRes = await dataReq.json();
                setSales(dataRes);
            }

            setLoadMessageVisible(false);

        }

        getData();

    }, []);



    const details = Sales.map((itm, idx) => {
        return <tr key={idx}><td>{itm.BrandName}</td><td>{itm.ItemsSold}</td></tr>
    });


    return (
        <>
            <h5>What's Selling</h5>

            {
                loadMessageVisible 
                ? 
                <>
                    Loading...
                </>
                :
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Qty</th>
                            </tr>
                        </thead>
                        <tbody>
                            {details}
                        </tbody>
                    </table>
                </>
            }

        </>
    );
}

export default WhatIsSelling;