import React, { useEffect, useState } from "react";
import { db, EntityId } from "../../js/db";
import { apiGet } from "../../js/shared";
import numeral from "numeral";
import * as engb from "numeral/locales/en-gb";
import "../../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  LineSeries,
  RadialChart,
  DiscreteColorLegend,
} from "react-vis";

function SalesThisMonth() {
  const [Sales, setSales] = useState([]);
  const [SalesValue, setSalesValue] = useState(0);
  const [Locations, setLocations] = useState([]);
  const [thisMonthChartData, setThisMonthChartData] = useState([]);
  const [loadMessageVisible, setLoadMessageVisible] = useState(true);
  const [LabelData, setLabelData] = useState([]);

  numeral.locale("en-gb");

  useEffect(() => {
    const getData = async () => {
      let locList = await db.Location.toArray();
      setLocations(locList);

      const entityId = await EntityId();

      const dataReq = await apiGet("/sale/thismonthssales", entityId);
      if (dataReq.ok && dataReq.status === 200) {
        const dataRes = await dataReq.json();
        setSales(dataRes);

        //Initial count
        let total = 0;
        let chartData = [],
          labelData = [];
        dataRes.forEach((sale) => {
          total += sale.Price;
        });

        const colors = [
          "#cd4a22",
          "#2f6ebc",
          "#80c20f",
          "#e4ee68",
          "#d2b600",
          "#8ce8c7",
          "#cd4a22",
          "#b2fbd3",
          "#2c4a90",
          "#8f7aab",
        ];

        dataRes.forEach((sale, idx) => {
          const foundLocation = locList.find((l) => {
            return l.LocationId === sale.LocationId;
          });
          if (foundLocation) {
            const width = (sale.Price / total) * 100;
            chartData.push({
              color: colors[idx],
              innerRadius: 50,
              radius: 100,
              angle: width,
            });
            labelData.push({
              title: foundLocation?.LocationName,
              color: colors[idx],
              strokeWidth: 6,
            });
          }
        });
        setSalesValue(total);
        setThisMonthChartData(chartData);
        setLabelData(labelData);
      }

      setLoadMessageVisible(false);
    };

    getData();
  }, []);

  const Breakdown = Sales.map((total, idx) => {
    const foundLocation = Locations.find((l) => {
      return l.LocationId === total.LocationId;
    });
    return (
      <tr key={idx}>
        <td>{foundLocation ? foundLocation.LocationName : "Unknown"}</td>
        <td>{numeral(total.Price).format("$0,0.00")}</td>
      </tr>
    );
  });

  return (
    <>
      <h5>Sales This Month</h5>

      {loadMessageVisible ? (
        <>Loading...</>
      ) : (
        <>
          <p>{numeral(SalesValue).format("$0,0.00")}</p>
          <table>
            <thead>
              <tr>
                <th>Shop</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>{Breakdown}</tbody>
          </table>
          <br />
          <RadialChart
            height={175}
            width={175}
            data={thisMonthChartData}
            colorType="literal"
          />
          <DiscreteColorLegend items={LabelData} />
        </>
      )}
    </>
  );
}

export default SalesThisMonth;
