import Dexie from "dexie";
import { apiGet, toast, getCookie } from "./shared.js";

export const db = new Dexie("SMDev2");

db.version(1).stores({
  OrderItems:
    "Id,Style,InternalCode,CategoryName,DisplayColour,ColourId,SingleCost,Markup,SizeGroupId,Pieces,Received,ProductId",
  OrderItemCombinations:
    "Id,OrderItemId,SizeId,Quantity,IsTopup,SizeLabel,Received,SortOrder",
  NewOrderItems:
    "Id++,Style,InternalCode,CategoryId,Colour,Price,Markup,SizeGroupId,ProductId,NOS,SOR",
  NewOrderItemCombinations:
    "Id++,OrderItemId,SizeId,SizeLabel,Quantity,SortOrder",
  SavedValues: "Item,Value",
  ItemsForAction: "id++,ItemData",
  Deliveries: "OrderItemCombinationId,Quantity",
  Topups: "Id++,OrderItemId,SizeId,SizeLabel,Quantity",
  Brand: "BrandId,BrandName,Hidden,IsB2B",
  Category: "CategoryId,CategoryName,Hidden",
  Colour: "Id++,ColourId,BrandId,ColourName,ColourNumber,DisplayName",
  SizeGroup: "SizeGroupId,SizeGroupLabel,SortOrder",
  Size: "SizeId,SizeGroupId,SizeLabel,SortOrder",
  Season: "SeasonId,SeasonName",
  Location:
    "LocationId,LocationName,Address,Phone,SortOrder,IsRotaLocation,IsSalesLocation,IsStockLocation",
  ProductCodes: "Id++,InternalCode",
});

export const EntityId = async () => {
  //return await db.Configuration.where('Name').equals('EntityId').first().then(itm => {return itm.Value});
  return getCookie("EntityId");
};

export const StaffMemberId = async () => {
  //return await db.Configuration.where('Name').equals('StaffMemberId').first().then(itm => {return itm.Value});
  return getCookie("StaffMemberId");
};

export const GetTimeout = async () => {
  return getCookie("DefaultTimeout");
};

export const SetSavedValue = async (item, value) => {
  const found = await db.SavedValues.where("Item").equals(item).toArray();
  if (found.length === 0) {
    db.SavedValues.put({
      Item: item,
      Value: value,
    });
  } else {
    db.SavedValues.update(item, { Value: value });
  }
};

export const GetSavedValue = async (item) => {
  const found = await db.SavedValues.where("Item").equals(item).toArray();
  if (found.length !== 0) {
    return found[0].Value;
  } else {
    return null;
  }
};

/* Cache some data locally to reduce server load */
export const UpdateCache = async () => {
  try {
    const entityId = await EntityId();
    if (entityId !== undefined && entityId !== "") {
      const brandRequest = await apiGet("/brand/smlist", entityId);
      if (brandRequest.ok && brandRequest.status === 200) {
        const brandData = await brandRequest.json();
        await db.Brand.clear();
        await db.Brand.bulkPut(brandData);
      }

      const categoryRequest = await apiGet("/category/list", entityId);
      if (categoryRequest.ok && categoryRequest.status === 200) {
        const categoryData = await categoryRequest.json();
        await db.Category.clear();
        await db.Category.bulkPut(categoryData);
      }

      const colourRequest = await apiGet("/brand/colour/listall", entityId);
      if (colourRequest.ok && colourRequest.status === 200) {
        const colourData = await colourRequest.json();
        await db.Colour.clear();
        await db.Colour.bulkPut(colourData);
      }

      const locationRequest = await apiGet("/location/list", entityId);
      if (locationRequest.ok && locationRequest.status === 200) {
        const locationData = await locationRequest.json();
        await db.Location.clear();
        await db.Location.bulkPut(locationData);
      }

      const sizeGroupRequest = await apiGet("/sizes/sizegroup/list", entityId);
      if (sizeGroupRequest.ok && sizeGroupRequest.status === 200) {
        const sizeGroupData = await sizeGroupRequest.json();
        await db.SizeGroup.clear();
        await db.SizeGroup.bulkPut(sizeGroupData);
      }

      const seasonRequest = await apiGet("/season/list", entityId);
      if (seasonRequest.ok && seasonRequest.status === 200) {
        const seasonData = await seasonRequest.json();
        await db.Season.clear();
        await db.Season.bulkPut(seasonData);
      }

      const sizeRequest = await apiGet("/sizes/size/list", entityId);
      if (sizeRequest.ok && sizeRequest.status === 200) {
        const sizeData = await sizeRequest.json();
        await db.Size.clear();
        await db.Size.bulkPut(sizeData);
      }
    }
  } catch (error) {
    toast("error", "Error updating local cache");
  }
};
