import React, { useState, useEffect } from "react";
import SalesToday from "./salestoday";
import SalesThisMonth from "./salesthismonth";
import WhatIsSelling from "./whatisselling";
import WhoIsWorking from "./whoisworking";
import SlateLogo from "../../images/SlateLogo.png";
import FamousLogo from "../../images/famouslogo.png";
import { EntityId, StaffMemberId } from "../../js/db";
import { apiGet } from "../../js/shared";

function Dashboard() {
  const [HasSalesThisMonth, setHasSalesThisMonth] = useState(false);
  const [EId, setEId] = useState();

  useEffect(() => {
    const PermissionsCheck = async () => {
      const entityId = await EntityId();
      setEId(entityId);
      const staffMemberId = await StaffMemberId();
      const req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/DashboardMonthlySales`,
        entityId
      );
      if (req.ok && req.status === 200) {
        const res = await req.json();
        if (res.Outcome === "Allow") setHasSalesThisMonth(true);
      }
    };

    PermissionsCheck();
  }, []);

  return (
    <>
      <div className="dbBranding text-center">
        {EId === "d8ebf079-08e5-41e7-af0b-71b469b0d2dd" && (
          <img alt="Slate Logo" src={SlateLogo} />
        )}
        {EId === "ca100a7f-08d6-4ecb-9acc-3ad6940189e2" && (
          <img alt="The Famous Logo" src={FamousLogo} />
        )}
      </div>

      <div className="grid-container">
        <div className="grid-20">
          <SalesToday></SalesToday>
        </div>
        <div className="grid-5">
          <br />
        </div>
        {HasSalesThisMonth ? (
          <>
            <div className="grid-20">
              <SalesThisMonth></SalesThisMonth>
            </div>
            <div className="grid-5">
              <br />
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="grid-20">
          <WhatIsSelling></WhatIsSelling>
        </div>
        <div className="grid-5">
          <br />
        </div>
        <div className="grid-20">
          <WhoIsWorking></WhoIsWorking>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
