import React from 'react';

function ActivityIndicator(props){

    const className = props.Visible ? 'overlay' : 'hidden';

    return(
        <div className={className}>
            <div className="content">
                <i className="fa fa-2x fa-cog fa-spin"></i>
            </div>
        </div>
    )
}

export default ActivityIndicator;