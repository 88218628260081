import React, {useState, useEffect} from 'react';
import {db, EntityId} from '../../js/db.js';
import AsyncSelect from 'react-select/async';
import { apiGet } from '../../js/shared.js';

function EmailListDropdown(props){

    const [emailList, setEmailList] = useState([]);

    const MatchEmail = async(val) => {
        
        const entityId = await EntityId();
        if(val.length > 2) {
            const req = await apiGet(`/auth/listmatches/${val}`, entityId);
            if(req.ok && req.status === 200) {
                const results = [];
                const res = await req.json();
                for(const match of res) {
                    results.push({
                        value: match.EmailAddress,
                        label: match.EmailAddress
                    });
                }
                return results;
            }
        }

    }



    const setSelection = e => {
        if(e=== null) {
            props.Selection('');
        } else {
            props.Selection(e.value)
        }
    }


    return(
        <>
            <AsyncSelect placeholder="Click here and start typing..." isMulti={false} isClearable={true} loadOptions={MatchEmail} onChange={(e) => setSelection(e)}></AsyncSelect>
        </>
    );

}

export default EmailListDropdown;