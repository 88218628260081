import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { EntityId, StaffMemberId } from "../../js/db.js";
import * as logo from "../../images/SMLogo.png";
import "./menu.scss";
import { apiGet, getCookie, getBaseUrl } from "../../js/shared.js";

function Menu(props) {
  const [FirstName, setFirstName] = useState("");
  const [myId, setMyId] = useState(undefined);
  const [HasReporting, setHasReporting] = useState(false);
  const [HasPerformanceReporting, setHasPerformanceReporting] = useState(false);
  const [HasConfiguration, setHasConfiguration] = useState(false);
  const [HasStaffList, setHasStaffList] = useState(false);
  const [HasRota, setHasRota] = useState(false);
  const [HasHoliday, setHasHoliday] = useState(false);
  const [MyEntityId, setMyEntityId] = useState("");
  const [BaseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const getDetails = async () => {
      const staffMemberId = await StaffMemberId();
      const entityId = await EntityId();
      const baseUrl = getBaseUrl;
      setMyId(staffMemberId);
      setMyEntityId(entityId);
      setBaseUrl(baseUrl);
      //const itm = await db.Configuration.where('Name').equals('FirstName').first();
      //setFirstName(itm.Value);
      setFirstName(getCookie("FirstName"));
    };

    const getPermissions = async () => {
      const staffMemberId = await StaffMemberId();
      const entityId = await EntityId();
      let req;
      let res;

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/PerformanceReporting`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasPerformanceReporting(true);
      }

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/StaffList`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasStaffList(true);
      }

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/Rota`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasRota(true);
      }

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/Holiday`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasHoliday(true);
      }

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/Reporting`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasReporting(true);
      }

      req = await apiGet(
        `/staffmember/permissions/${staffMemberId}/Configuration`,
        entityId
      );
      if (req.ok && req.status === 200) {
        res = await req.json();
        if (res.Outcome === "Allow") setHasConfiguration(true);
      }
    };

    getDetails();
    getPermissions();
  }, [FirstName]);

  //const initials = props.user.FirstName.substr(0,1).toUpperCase() + props.user.LastName.substr(0,1).toUpperCase();
  return (
    <div>
      <div className="branding">
        <img alt="Logo" src={logo}></img>
      </div>
      <p className="userName">
        Welcome, <b>{FirstName}</b>
      </p>
      <p className="userActions">
        <Link to="/">
          <i className="fa fa-home mr-10"></i>Home
        </Link>
        <span className="link" onClick={props.LogoutAction}>
          <i className="fa fa-sign-out-alt mr-10"></i>Log Out
        </span>
      </p>

      <div className="menuCard">
        <p>My Account</p>
        <ul>
          <li>
            <Link to={`/StaffMember/Edit/${myId}`}>My Profile</Link>
          </li>
        </ul>
      </div>
      <div className="menuCard">
        <p>Documents</p>
        <ul>
          <li>
            <Link to="/documents">Documents</Link>
          </li>
        </ul>
      </div>
      <div className="menuCard">
        <p>Orders</p>
        <ul>
          <li>
            <Link to="/order">Order Management</Link>
          </li>
        </ul>
      </div>
      <div className="menuCard">
        <p>Stock</p>
        <ul>
          <li>
            <Link to="/product">Product Management</Link>
          </li>
          <li>
            <Link to="/stock">Current Stock</Link>
          </li>
        </ul>
      </div>
      <div className="menuCard">
        <p>Sales</p>
        <ul>
          <li>
            <Link to="/sales/history">Sales History</Link>
          </li>
          <li>
            <Link to="/customers">Customers</Link>
          </li>
        </ul>
      </div>
      {HasStaffList || HasRota || HasHoliday ? (
        <div className="menuCard">
          <p>Staff Management</p>
          <ul>
            {HasStaffList ? (
              <>
                <li>
                  <Link to="/staffmember">Staff Members</Link>
                </li>
                <li>
                  <Link to="/staffmember/permissions">Permissions</Link>
                </li>
              </>
            ) : (
              <></>
            )}
            {HasRota ? (
              <>
                <li>
                  <Link to="/rota">Rota</Link>
                </li>
              </>
            ) : (
              <></>
            )}
            {HasHoliday ? (
              <>
                <li>
                  <Link to="/staffmember/holidayperiods">Holiday</Link>
                </li>
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
      {HasReporting ? (
        <div className="menuCard">
          <p>Reporting</p>
          <ul>
            <li>
              <Link to="/reporting/orders">Orders</Link>
            </li>
            <li>
              <Link to="/reporting/relocations">Relocations</Link>
            </li>
            <li>
              <Link to="/reporting/returns">Returns</Link>
            </li>
            <li>
              <Link to="/reporting/sales">Sales</Link>
            </li>
            {HasPerformanceReporting ? (
              <>
                <li>
                  <Link to="/reporting/averageperformance">
                    Average Performance
                  </Link>
                </li>
                <li>
                  <Link to="/reporting/salesperformance">
                    Sales Performance
                  </Link>
                </li>
                <li>
                  <Link to="/reporting/staffperformance">
                    Staff Performance
                  </Link>
                </li>
                <li>
                  <Link to="/reporting/endofday">End of day</Link>
                </li>
              </>
            ) : (
              <></>
            )}
            <li>
              <Link to="/reporting/SellThrough">Sell-Through</Link>
            </li>
            <li>
              <Link to="/reporting/stock">Live Stock</Link>
            </li>
            <li>
              <Link to="/reporting/historicstock">Historic Stock</Link>
            </li>
            <li>
              <Link to="/reporting/vouchers">Vouchers</Link>
            </li>
            <li>
              <Link to="/reporting/undelivered">Undelivered Items</Link>
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}

      <div className="menuCard">
        <p>Configuration</p>
        <ul>
          <li>
            <Link to="/brand">Brands</Link>
          </li>
          {HasConfiguration ? (
            <>
              <li>
                <Link to="/sizes">Sizes &amp; Groups</Link>
              </li>
              <li>
                <Link to="/location">Stores &amp; Locations</Link>
              </li>
              <li>
                <Link to="/category">Categories</Link>
              </li>
              <li>
                <Link to="/season">Seasons</Link>
              </li>
              <li>
                <Link to="/currency">Currencies</Link>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
      <br />
      <p>
        <small>
          <b>Entity Id</b>
          <br />
          {MyEntityId}
          <br />
          <b>Base Url</b>
          <br />
          {BaseUrl}
          <br />
        </small>
      </p>
      <br />
    </div>
  );
}

export default Menu;
