import React, {useState, useEffect} from 'react';
import {db} from '../../js/db.js';
import Select from 'react-select';

function LocationListDropdown(props){

    const [locationList, setLocationList] = useState([]);
    const [value, setValue] = useState(undefined);


    const selectValue = e => {
        setValue(e);
        props.Selection(e.value);
    }


    useEffect(() => {

        const getData = async() => {
            
            let data = await db.Location.toArray();

            //Sort locations
            data = data.sort((a,b) => {
                return a.SortOrder < b.SortOrder ? -1 : 1;
            });

            //Add prompts as needed
            if(props.Excludes.All !== true)
                data = [{LocationId: 0, LocationName: 'All'}, ...data];
            if(props.Excludes.Select !== true)
                data = [{LocationId: -1, LocationName: 'Select'}, ...data];
            if(props.Requirements.IsRotaLocation === true)
                data = data.filter(l => l.LocationId <= 0 || l.IsRotaLocation === true);
            if(props.Requirements.IsStockLocation === true)
                data = data.filter(l => l.LocationId <= 0 || l.IsStockLocation === true);
            if(props.Requirements.IsSalesLocation === true)
                data = data.filter(l => l.LocationId <= 0 || l.IsSalesLocation === true);

            const locations = data.map((l,idx) => {
                return {
                    value: l.LocationId,
                    label: l.LocationName
                }
            });
            setLocationList(locations);

         

        }

        if(locationList.length === 0) {
            getData();
        } else {
            for(const loc of locationList) {
                if(loc.value === props.Value) {
                    setValue(loc);
                }
            }               
        }

    }, [props, locationList]);


    return(
        <>  
            <Select isMulti={props.Multiple} value={value} onChange={e => selectValue(e)} options={locationList}></Select>
        </>
    );

}

export default LocationListDropdown;